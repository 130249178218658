.example:not(:first-child) {
    margin-top: 1.5rem;
}
.example .tab-content {
    /* background-color: shift-color(#ebedef, -70) !important; */
    background-color: #ebedef !important;
}
.dark-theme {
    background-color: rgba(255, 255, 255, .1) !important;
}
.example code[class*="language-"], .example pre[class*="language-"] {
    font-size: 0.875rem !important;
}
.example :not(pre) > code[class*="language-"], .example pre[class*="language-"] {
    background: transparent;
}
.example + p {
    margin-top: 1.5rem;
}
.example .preview + p, .example .preview .col + p {
    margin-top: 2rem;
}
.example .preview >
.form-control + .form-control, .example .preview .col >
.form-control + .form-control {
    margin-top: 0.5rem;
}
.example .preview >
.nav + .nav, .example .preview .col >
.nav + .nav, .example .preview >
.alert + .alert, .example .preview .col >
.alert + .alert, .example .preview >
.navbar + .navbar, .example .preview .col >
.navbar + .navbar, .example .preview >
.progress + .progress, .example .preview .col >
.progress + .progress {
    margin-top: 1rem;
}
.example .preview >
.dropdown-menu, .example .preview .col >
.dropdown-menu {
    position: static;
    display: block;
}
.example .preview >
:last-child, .example .preview .col >
:last-child {
    margin-bottom: 0;
}
.example .preview >
svg + svg, .example .preview .col >
svg + svg, .example .preview >
img + img, .example .preview .col >
img + img {
    margin-left: 0.5rem;
}
.example .preview >
.btn, .example .preview .col >
.btn, .example .preview >
.btn-group, .example .preview .col >
.btn-group {
    margin: 0.25rem 0.125rem;
}
.example .preview >
.btn-toolbar + .btn-toolbar, .example .preview .col >
.btn-toolbar + .btn-toolbar {
    margin-top: 0.5rem;
}
.example .preview >
.list-group, .example .preview .col >
.list-group {
    max-width: 400px;
}
.example .preview >
[class*="list-group-horizontal"], .example .preview .col >
[class*="list-group-horizontal"] {
    max-width: 100%;
}
.example .preview .fixed-top, .example .preview .col .fixed-top, .example .preview .sticky-top, .example .preview .col .sticky-top {
    position: static;
    margin: -1rem -1rem 1rem;
}
.example .preview .fixed-bottom, .example .preview .col .fixed-bottom {
    position: static;
    margin: 1rem -1rem -1rem;
}
.example .preview .pagination, .example .preview .col .pagination {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

