@use '../scss/variables' as v;
// Here you can add other styles
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,900);

@font-face {
  font-family: 'Cerebri';
  src: url('../assets/fonts/cerebrisans-light.eot');
  src: local('Cerebri-sans Light'), url('../assets/fonts/cerebrisans-light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Cerebri';
  src: url('../assets/fonts/cerebrisans-regular.eot');
  src: local('Cerebri-sans Regular'), url('../assets/fonts/cerebrisans-regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Cerebri';
  src: url('../assets/fonts/cerebrisans-medium.eot');
  src: local('Cerebri-sans Medium'), url('../assets/fonts/cerebrisans-medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Cerebri';
  src: url('../assets/fonts/cerebrisans-semibold.eot');
  src: local('Cerebri-sans Semibold'),
    url('../assets/fonts/cerebrisans-semibold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Cerebri';
  src: url('../assets/fonts/cerebrisans-bold.eot');
  src: local('Cerebri-sans Bold'), url('../assets/fonts/cerebrisans-bold.woff') format('woff');
  font-weight: 700;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: v.$base-color;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: v.$base-color;
}

input[type='number'] {
  &::-webkit-inner-spin-button {
    opacity: 1;
  }
}

.text-primary {
  color: v.$primary-color !important;
}

.hand {
  cursor: pointer;
}

.btn {
  font-size: 0.875rem;
}

.custom-header {
  display: flex;
  justify-content: space-between;
}

.form-control:focus {
  box-shadow: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: v.$base-color;
}

.input-error {
  color: #eb2a08;
}

.outdanger {
  &:hover,
  &:focus {
    color: #ffffff;
  }
}

a {
  color: v.$base-color;
  cursor: pointer;
}

.form-control:focus {
  border-color: #2eb85c;
}

.copy-text {
  cursor: pointer;
}

.badge {
  line-height: normal;
}

label {
  margin-bottom: 4px !important;
}

body {
  color: #6c757d !important;
  font-size: 0.875rem;
}

body {
  background-color: #f5f6f8;
  color: #6c757d;
  font-family: 'Nunito', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;

  .page-title {
    font-size: 1.25rem;
    margin: 0;
    color: #323a46;
    font-family: 'Cerebri';
  }

  .page-title-right {
    nav {
      float: right;
    }
  }

  .dropdown-item {
    font-size: 0.875rem;
  }

  .bg-light {
    background-color: v.$color-lighgrey;
  }

  .header.header-sticky {
    background: #fff;

    .header-toggler {
      svg {
        fill: #6e19c2;
        color: #6e19c2;
      }
    }

    .nav-link {
      color: #ffffff;
    }
  }

  .head-tittle {
    font-size: 1rem;
    font-weight: 700;
  }

  .btn {
    &.btn-success {
      background-color: v.$primary-color;
      border-color: v.$primary-color;
      color: v.$color-white;
      box-shadow: 0 0 0 0 rgb(120, 33, 235, 0.2);

      :hover {
        // background-color: rgba(44, 56, 74, 0.95);
        border-color: v.$primary-color;
      }

      .ative,
      :active {
        background-color: v.$primary-color;
        border-color: v.$primary-color;
      }

      :disabled {
        background-color: #000015;
        border-color: v.$primary-color;
      }
    }
  }

  .serial_no {
    .card-body {
      .rdt_Table {
        .rdt_TableHead {
          .rdt_TableHeadRow {
            .rdt_TableCol {
              &:first-child {
                max-width: 80px;
                min-width: 80px;
              }
            }
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            .rdt_TableCell {
              &:first-child {
                max-width: 80px;
                min-width: 80px;
              }
            }
          }
        }
      }
    }
  }

  .rdt_TableHeadRow {
    .rdt_TableCol {
      font-size: 0.875rem;
      color: #6c757d;
      font-weight: 700;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 140px;

      @media (max-width: 991px) {
        min-width: 180px;
      }

      div {
        overflow: visible;
        white-space: inherit;
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        font-size: 0.875rem;
        color: #6c757d;
        font-weight: 400;
        padding: 10px;
        min-width: 200px;

        @media (max-width: 991px) {
          min-width: 180px;
        }

        .update-user {
          width: auto;
          color: #000;
          background: transparent;
          border-color: transparent;
          padding: 0;
          svg {
            width: 15px;
            color: #000;
          }
          &.update-user:not(:last-child) {
            margin-right: 10px;
          }
          &.edit-btn svg {
            color: #f9b115;
          }
          &.trash-btn svg {
            color: #e55353;
          }
        }
      }
    }
  }

  .form-control {
    font-size: 0.875rem;
    border-color: #ced4da;
    min-height: 38px;
    line-height: normal;
    background-color: #ffffff;
  }

  .input-group-text {
    background-color: v.$color-lighgrey;
  }

  .btn-close:hover {
    opacity: 1;
    background-color: #fff;
  }
  .header-title {
    font-size: 1rem;
    margin: 0 0 7px 0;
  }
  .sub-header {
    font-size: 0.875rem;
    margin-bottom: 24px;
    color: var(--ct-text-muted);
  }
}

button,
input {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.top-widgets {
  .bg-primary {
    background-color: rgba(102, 88, 221, 0.25) !important;
    border-radius: 50%;
    border: 1px solid v.$primary-color !important;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: v.$primary-color;
    }
  }

  .bg-info {
    background-color: rgba(51, 153, 255, 0.25) !important;
    border-radius: 50%;
    border: 1px solid #39f !important;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #39f;
    }
  }

  .bg-warning {
    background-color: rgba(249, 177, 21, 0.25) !important;
    border-radius: 50%;
    border: 1px solid #f9b115 !important;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #f9b115;
    }
  }
  .bg-success {
    background-color: rgb(21 249 37 / 25%) !important;
    border-radius: 50%;
    border: 1px solid #5eb828 !important;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #5eb828;
    }
  }
  .bg-danger {
    background-color: rgba(229, 83, 83, 0.25) !important;
    border-radius: 50%;
    border: 1px solid #e55353 !important;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #e55353;
    }
  }

  div {
    &:nth-child(2) {
      margin-left: auto;
      text-align: right;
      text-transform: inherit !important;
      font-size: 0.825rem !important;
      font-family: 'Nunito', sans-serif !important;
      .fs-6 {
        color: #343a40 !important;
      }
    }
  }
}

.fs-6 {
  font-size: 1.7rem !important;

  @media (max-width: 1300px) {
    font-size: 1.6rem !important;
  }

  @media (max-width: 991px) {
    font-size: 1.3rem !important;
  }
}

.spinner_block {
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: 767px) {
    left: 0px;
  }
}

.spinner-block {
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
}

.gelpCx {
  & > div {
    height: 120px;
  }
}

.mobile-navigation {
  display: none;

  @media (max-width: 767px) {
    display: inline-block;
  }
}

.name-desk {
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
}

span.tox-statusbar__branding {
  display: none !important;
}

.w-320 {
  width: 320px !important;
  max-width: 100%;
}

.right-border {
  border-right: 1px solid #ddd;
}

button.coin-button {
  width: 100px;
  background-color: #fff;
  padding: 3px 10px;
  text-align: left;
  border: 1px solid #fff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 12px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.title-h4 {
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 10px;
  color: #323a46;
  font-family: 'Cerebri';
}

// @media (min-width: 1450px) {
//   .main-outer {
//     max-width: 1920px;
//     margin: auto;
//     overflow: hidden;
//     position: relative;
//   }
//   .sidebar.sidebar-fixed {
//     position: absolute !important;
//   }
//   .padding-0 .card-body {
//     padding: 19px !important;
//   }
// }

// common css for images edit and delete style
.newEditIcon svg {
  width: 25px;
  color: #41ff41 !important;
}

.newDeleteIcon svg {
  width: 25px;
  color: red !important;
}

.commonImgs {
  position: relative;
  display: inline-block;
}

.commonImgs:hover .edit {
  display: block;
  z-index: 999;
}

.commonImgs:hover::before {
  content: '';
  display: block;
  position: absolute;
  background: #1411119e;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
}

.edit {
  padding-top: 7px;
  padding-right: 7px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}
